






import chartJS from 'chart.js';
import moment from 'moment';

import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { APIUsageDatum, PubSubUsageDatum, UsageData } from '../store/types';

function afterBuildTicks(scale: any, ticks: number[]): number[] {
  const maxTicks = 20;
  const maxLog = Math.log(ticks[0]);
  const minLogDensity = maxLog / maxTicks;

  const out: number[] = [];
  let currLog = -Infinity;
  ticks.reverse().forEach(tick => {
    const log = Math.max(0, Math.log(tick));
    if (log - currLog > minLogDensity) {
      out.push(tick);
      currLog = log;
    }
  });

  return out;
}

@Component({
  name: 'ExtensionUsageGraph'
})
export default class ExtensionUsageGraph extends Vue {
  @Prop() public usageData: UsageData;

  public chart: any;
  @Prop(Number) public startTime!: number;
  @Prop(Number) public endTime!: number;

  @Watch('usageData')
  public onUsageDataUpdate() {
    this.update();
  }

  // We set time.min/max on the chart's X axis scale in order to render a full month, regardless of how many data points
  // we have.
  // When time.min/max are set, ChartJS strictly uses those values for the lower/upper bounds when rendering the chart.
  // (https://www.chartjs.org/docs/latest/axes/cartesian/time.html#configuration-options)
  // These functions pad the values given to time.min/max by a set number of days, to prevent the days on the edges from
  // being cut off.
  public timeLowerBound() {
    return this.startTime - 86400 * 2;
  }

  public timeUpperBound() {
    return this.endTime + 86400 * 2;
  }

  public mounted() {
    const canvas: HTMLCanvasElement = this.$refs.usageChart as HTMLCanvasElement;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
    this.chart = new chartJS.Chart(ctx, {
      type: 'bar',
      options: {
        responsive: true,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                unit: 'day',
                min: this.timeLowerBound().toString(),
                max: this.timeUpperBound().toString(),
                parser: 'X'
              }
            }
          ],

          yAxes: [
            {
              gridLines: {
                display: false
              },
              id: 'api',
              type: 'logarithmic',
              position: 'left',
              ticks: {
                callback(value, index, values) {
                  return value.toString();
                }
              },
              afterBuildTicks
            },
            {
              gridLines: {
                display: false
              },
              id: 'pubsub',
              type: 'logarithmic',
              position: 'right',
              ticks: {
                callback(value, index, values) {
                  return value.toString();
                }
              },
              afterBuildTicks
            }
          ]
        }
      }
    });

    this.update();
  }

  public update() {
    if (!this.usageData) {
      return;
    }

    this.chart.data.datasets = [];

    // Format the data
    const formattedAPIUsage: any[] = [];
    const apiUsageData: APIUsageDatum[] = this.usageData.api_usage;
    const apiUsageGroupedData = new Map<number, number>();

    apiUsageData.forEach(datum => {
      const date = moment(datum.Timestamp)
        .startOf('day')
        .valueOf();
      if (apiUsageGroupedData.has(date)) {
        apiUsageGroupedData.set(date, apiUsageGroupedData.get(date)! + datum.Calls);
      } else {
        apiUsageGroupedData.set(date, datum.Calls);
      }
    });

    apiUsageGroupedData.forEach((calls, date) => {
      formattedAPIUsage.push({
        x: moment(date),
        y: calls
      });
    });

    formattedAPIUsage.sort((a, b) => {
      return a.x - b.x;
    });

    this.chart.data.datasets.push({
      label: 'API Calls',
      yAxisID: 'api',
      backgroundColor: '#02c7c7',
      data: formattedAPIUsage
    });

    const formattedPubSubUsage: any[] = [];
    const pubSubUsageData: PubSubUsageDatum[] = this.usageData.pub_sub_usage;
    const pubSubUsageGroupedData = new Map<number, number>();

    pubSubUsageData.forEach(datum => {
      const date = moment(datum.Timestamp)
        .startOf('day')
        .valueOf();
      if (pubSubUsageGroupedData.has(date)) {
        pubSubUsageGroupedData.set(date, pubSubUsageGroupedData.get(date)! + datum.Messages);
      } else {
        pubSubUsageGroupedData.set(date, datum.Messages);
      }
    });

    pubSubUsageGroupedData.forEach((messages, date) => {
      formattedPubSubUsage.push({
        x: moment(date),
        y: messages
      });
    });

    formattedPubSubUsage.sort((a, b) => {
      return a.x - b.x;
    });

    this.chart.data.datasets.push({
      label: 'Pub Sub Usage',
      yAxisID: 'pubsub',
      backgroundColor: '#6422c6',
      data: formattedPubSubUsage
    });

    this.chart.update();
  }
}
