


















import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import MonthSelector from '@/components/MonthSelector.vue';
import ExtensionUsageGraph from '@/components/ExtensionUsageGraph.vue';
import { UsageData } from '../store/types';
import moment from 'moment';

@Component({
  name: 'ExtensionInfo',
  components: { MonthSelector, ExtensionUsageGraph, Loading }
})
export default class ExtensionInfo extends Vue {

  get totalApiUsage() {
    if (this.usageData) { return this.usageData.total_api_usage; }

    return 0;
  }

  get totalPubsubUsage() {
    if (this.usageData) { return this.usageData.total_pub_sub_usage; }

    return 0;
  }

  get usageCost() {
    if (this.usageData) { return this.usageData.cost_string; }

    return 'Unknown';
  }

  get estimatedCost() {
    if (this.usageData) { return this.usageData.estimated_cost_string; }

    return 'Unknown';
  }

  public extensionId: string | null = null;
  public usageData: UsageData | null = null;
  public startTime: number | null = null;
  public endTime: number | null = null;

  public loading: boolean = true;

  public created() {
    this.extensionId = this.$route.params.extension_id;
  }

  public mounted() {
    this.loading = true;

    this.$api.get(`/api/extension/${this.extensionId}/activeMonths`).then((response) => {
      const monthSelector = this.$refs.monthSelector as MonthSelector;
      let currentMonth = null;

      if (monthSelector) {
        monthSelector.setActiveMonths(response.data.activeMonths);
        if (monthSelector.activeMonths.length > 0) {
          currentMonth = monthSelector.activeMonths[0];
        }
      }

      if (currentMonth) {
        this.changeStartTime(currentMonth);
      }

      this.loading = false;
    });
  }

  public isCurrentMonthSelected() {
    return this.startTime && moment().month() === moment.unix(this.startTime).month();
  }

  public changeStartTime(month: any) {
    if (month) {
      this.loading = true;
      this.$api
        .get(`/api/extension/${this.extensionId}/usageData/${month.startTime}/${month.endTime}`)
        .then((response) => {
          this.usageData = response.data;
          this.startTime = month.startTime;
          this.endTime = month.endTime;
          return true;
        })
        .then(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }
}
