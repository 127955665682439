






import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Loading'
})
export default class Loading extends Vue {}
