
































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Carousel, Slide } from 'vue-carousel';
import MonthAndYear from '@/components/MonthAndYear.vue';

@Component<any>({
  components: { MonthAndYear, Carousel, Slide }
})
export default class MonthSelector extends Vue {
  @Prop(String) public extensionId!: string;
  @Prop(Number) public startTime!: number;
  @Prop(Number) public endTime!: number;

  @Watch('activeMonths')
  private updateMonth() {
    this.setMonth(this.activeMonths[0]);
  }

  public activeMonths: any[] = [];
  public currentMonth: string | null = "";
  public needsNav: boolean = false;
  public canAdvanceForward: boolean = true;
  public canAdvanceBackward: boolean = true;

  public $refs!: Vue['$refs'] & {
    carousel: Carousel;
  };

  public mounted() {
    if (this.activeMonths.length > 0) {
      this.setMonth(this.activeMonths[0]);
      this.$refs.carousel.goToPage(this.$refs.carousel.pageCount);
      this.canAdvanceForward = this.$refs.carousel.canAdvanceForward;
      this.canAdvanceBackward = this.$refs.carousel.canAdvanceBackward;
      this.needsNav = this.$refs.carousel.pageCount > 1;
    } else {
      this.setMonth(null);
    }
  }

  public setMonth(month: any) {
    this.currentMonth = month;
    this.$emit('setMonth', month);
  }

  public get selectedMonth() {
    return this.currentMonth;
  }

  public advancePage(direction: string) {
    this.$refs.carousel.advancePage(direction);
    this.canAdvanceForward = this.$refs.carousel.canAdvanceForward;
    this.canAdvanceBackward = this.$refs.carousel.canAdvanceBackward;
  }

  public setActiveMonths(activeMonths: any[]) {
    this.activeMonths = activeMonths;
  }
}
