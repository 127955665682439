




import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'MonthAndYear'
})
export default class MonthAndYear extends Vue {
  @Prop() public month!: any;
  @Prop(Number) public startTime: number;

  public isSelected: boolean = false;

  public created() {
    this.onStartTimeChanged();
  }

  @Watch('startTime')
  public onStartTimeChanged() {
    if (this.startTime === this.month.startTime) {
      this.isSelected = true;
    } else {
      this.isSelected = false;
    }
  }

  public selectMonth() {
    this.$emit('select', this.month);
    this.isSelected = true;
  }
}
